import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import AuthService from '@/services/auth';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Página Inicial' },
    beforeEnter: (to, from, next) => {
      const user = AuthService.getCurrentUser();
      if (!user) {
        next('/login');
      } else if (user.role === 'admin') {
        next('/admin');
      } else {
        next('/user-home'); // Ajuste conforme necessário para a página inicial do usuário
      }
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/clients/:id',
    name: 'ClientDetailsPage',
    component: () => import('../views/ClientDetailsPage.vue'),
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import('../components/User/ProfileComponent.vue'),
  },
  {
    path: '/assistants/:assistantId/edit',
    name: 'AssistantDetailsPage',
    component: () => import('../components/Assistant/AssistantDetailsPage.vue'),
  },
  {
    path: '/agents/:agentId/edit',
    name: 'AgentDetailsPage',
    component: () => import('../views/AgentDetailsPage.vue'),
  },
  {
    path: '/agents/:agentId/execute',
    name: 'ExecuteAgent',
    component: () => import('../views/ExecuteAgentPage.vue'),
  },
  {
    path: '/agents/',
    name: 'AgentsPage',
    component: () => import('../views/AgentsPage.vue'),
  },
  {
    path: '/chat/:assistantId',
    name: 'ChatPage',
    component: () => import('../views/ChatPage.vue'),
  },
  {
  path: '/journeys/:journeyId',
  name: 'JourneyDetails',
  component: () => import('../views/JourneyDetailsPage.vue'),
  props: true,
  },
  {
  path: '/user/journey',
  name: 'UserJourney',
  component: () => import('../views/UserJourneyPage.vue'),
  },
  {
    path: '/user/agents',
    name: 'UserAgent',
    component: () => import('../views/UsersAgentPage.vue'),
  },
  {
  path: '/user/assistants',
  name: 'UserAssistant',
  component: () => import('../views/UsersAssistantPage.vue'),
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: () => import('../views/AdminPage.vue'),
    meta: { title: 'Administração' },
    beforeEnter: (to, from, next) => {
      const user = AuthService.getCurrentUser();
      if (!user) {
        next('/login');
      } else if (user.role === 'admin') {
        next();
      } else {
        next('/'); // Ou redirecionar para uma página específica para usuários não-admin
      }
    }
  },
  {
    path: '/user-home',
    name: 'UserAgentsPage',
    component:  () => import('../views/HomeUserPage.vue'),
    beforeEnter: (to, from, next) => {
      const user = AuthService.getCurrentUser();
      if (!user) {
        next('/login');
      } else if (user.role !== 'admin') {
        next();
      } else {
        next('/admin');
      }
    }
  },

  {
    path: '/users/:clientId/journeys/:journeyId/run',
    name: 'RunUserJourney',
    component: () => import('../views/RunUserJourneyPage.vue'),
  },
  {
    path: '/sair',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      AuthService.logout()
        .then(() => {
          next('/login');
        })
        .catch(error => {
          console.error("Error logging out:", error);
          next('/login'); // Redireciona para a página de login mesmo em caso de erro
        });
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;